import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ProjectCard from "../../../components/project-card";
import Avatar from "../../../components/avatar";
import DavidLevyImg from "../../../../assets/images/davidlevy_avatar_linkedin.jpg";
import YoutubeImg from "../../../../assets/images/youtube.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Team`}</h2>
    <Avatar imageUrl={DavidLevyImg} link="https://www.linkedin.com/in/davidlevy/" title="David Levy" mdxType="Avatar">
  <h6>CTO</h6>
  David has more than 20 years experience in web Development, working with Large
  Accounts and startups
    </Avatar>
    <p>{`Our tech team is led by Israeli senior engineers, and use Agile methodologies to bring maximum transparency and control to the customer.`}</p>
    <br />
    <br />
    <h2>{`Recent Projects`}</h2>
    <ProjectCard title="Atid Wire" link="https://atidbanking.com" bg="linear-gradient(to bottom, #111 0%, #111 100%)" mdxType="ProjectCard">
  Bank as a Service platform
  <br />
  Stack: AWS, React Native, React, GraphQL, Node.js, MongoDB, Java
    </ProjectCard>
    <ProjectCard title="Leadly" link="https://leadly.com" bg="linear-gradient(to bottom, #111 0%, #111 100%)" mdxType="ProjectCard">
  Lead management platform
  <br />
  Stack: React, Hasura, GraphQL, Postgres
    </ProjectCard>
    <ProjectCard title="AirborneX" link="https://airbornex.io/" bg="linear-gradient(to bottom, #111 0%, #111 100%)" mdxType="ProjectCard">
  Helicopters Marketplace based on ShareTribe GO solution
    </ProjectCard>
    <ProjectCard title="Veepee" link="https://m.veepee.fr/" bg="linear-gradient(to bottom, #111 0%, #111 100%)" mdxType="ProjectCard">
  Mobile WebApp and Backoffice panel
  <br />
  Stack: React, Marionette, BackboneJS and jQueryMobile
    </ProjectCard>
    <ProjectCard title="MatchesFashion" link="https://play.google.com/store/apps/details?id=com.matchesfashion.android&hl=en&gl=US" bg="linear-gradient(to bottom, #111 0%, #111 100%)" mdxType="ProjectCard">
  Android App
    </ProjectCard>
    <ProjectCard title="Eiffage" link="https://www.eiffage.fr/" bg="linear-gradient(to bottom, #111 0%, #111 100%)" mdxType="ProjectCard">
  Android Hybrid App to report Repair works
  <br />
  Stack: Ionic3
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      