import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Ask for a quote : `}<a parentName="h4" {...{
        "href": "mailto:david@sparklingbytes.net"
      }}>{`david@sparklingbytes.net`}</a></h4>
    <p>{`🇮🇱 We are based in Raanana, Israel`}</p>
    <p>{`We speak English, Français, עברית`}</p>
    <p>{`Checkout free Zoom Virtual Backgrounds with `}<a parentName="p" {...{
        "href": "http://www.sparklingbytes.net/virtwall-zoom-background/"
      }}>{`VirtWall`}</a></p>
    <p>{`Lion by Ji Sub Jeong from the Noun Project`}</p>
    <div style={{
      "display": "flex",
      "flexDirection": "row",
      "alignItems": "center"
    }}>
  <iframe allowtransparency="true" scrolling="no" frameBorder="no" src="https://w.soundcloud.com/icon/?url=http%3A%2F%2Fsoundcloud.com%2Fdavid-levy&color=orange_white&size=48" style={{
        "width": "48px",
        "height": "48px",
        "display": "inline-block"
      }}></iframe>
  <span style={{
        "marginLeft": "8px"
      }}>Listen to my music on SoundCloud</span>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      