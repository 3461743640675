import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Logo from "../../../../assets/svg/logo.inline.svg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Logo className="lionLogo" mdxType="Logo" />
    <h1>{`SparklingBytes`}</h1>
    <h3>{`We code your business`}</h3>
    <ul>
      <li parentName="ul">{`Senior engineers from the Startup Nation`}</li>
      <li parentName="ul">{`Modern Web and Mobile Development`}</li>
      <li parentName="ul">{`Agile and experienced in full Remote`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      