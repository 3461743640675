/** @jsx jsx */
import { jsx } from "theme-ui"
import React from 'react';

const Plans = () => {
    return (
       
    <section id="plans"  sx={{
      display: `grid`,
      gridGap: [1, 1, 1, 1],
      gridTemplateColumns: [`1fr`, `1fr`, `repeat(3, 1fr)`],
    //   h2: { gridColumn: `-1/1`, color: `white !important` },
    }}
  >
    <article>
      <h4>Boost</h4>
      <h5>Pay-per-hour</h5>
      <p>For codebase Audit, on-demand expertise</p>
    </article>

    <article>
      <h4>Journey</h4>
      <h5>Pay-per-day</h5>
      <p>For long term evolutive maintenance, Tech Lead as a Service</p>
    </article>

    <article>
      <h4>Launcher</h4>
      <h5>Pay-per-project</h5>
      <p>For full project development including PoC, MVP</p>
    </article>
  </section>
    )
}


  export default Plans;