import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Plans from '../../../components/plans';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Services`}</h2>
    <p>{`We specialize in multiple backend and frontend technologies, with a strong experience in e-commerce and mobile markets.`}</p>
    <p>{`We build robust software, stick to your deadlines, with a focus on three key factors:
Performances, Usability and Sustainability`}</p>
    <p>{`We love challenges, best practices and clean code.`}</p>
    <p>{`We value responsiveness, proactivity and respect of the timelines.`}</p>
    <Plans mdxType="Plans" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      